/*------------------------------------------------------------
footer
------------------------------------------------------------*/

/*
------------------------------------------------------------*/
.c-footer {
	background: #9e9e9e;
	position: relative;
	.l-wrapper {
		// display: flex;
		// justify-content: space-between;
		align-items: flex-end;
		
		&:after {
		    content: "";
		    display: table;
		    clear: both;
		  }
		@include sp{
			display:block;
		}
	}
	&__left {
		float: left;
		@include sp{
			text-align: center;
			float: none;
		}
		&--img {
			padding: 32px 0 32px;
			@include sp{
				padding: 32px 0 50px;
			}
		}
		&--address {
			margin-bottom: 30px;
			@include sp{
				margin-bottom: 0;
			}
		}
		.mail {
			padding-top: 5px;
		}
		p,a {
			color: #fff;
			font-size: 1.4rem;
			font-family: "Noto Sans CJK JP Light", "ヒラギノ角ゴ Pro","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,sans-serif;
			text-decoration: none
		}
	}
	&__right {
		float: right;
		text-align: right;
		margin: 100px 22px 36px 0;
		@include sp{
			text-align: center;
			margin: 0;
			padding:10px 0 25px;
			float:none;
		}
		.privacy {
			color: #fff;
			font-size: 1.4rem;
			font-family: "Shree Devanagari 714";
			text-decoration: none;
			&:hover{
				text-decoration: underline;
			}
			@include sp{
				display: inline-block;
			}
		}
		.copyright {
			color: #fff;
			font-size: 1.2rem;
			font-family: "Shree Devanagari 714";
			@include sp{
				display: inline-block;
			}
		}
	}
	.c-totop {
		position: absolute;
		right: 25px;
		bottom: 0;
		transform: rotate(-90deg);
		@include sp{
			right: 10px;
			float:none;
		}
		a {
			text-decoration: none;
			color: #999;
			font-family: "Shree Devanagari 714";
			font-size: 3.2rem;
			@include sp{
				font-size: 2.6rem;
			}
			&:hover{
				color: #9e9e9e;
			}
			&:after {
				content: ">";
				font-size: 3.4rem;
				font-family: "Diot";
				top: 0;
				position: absolute;
				right: -28px;
				@include sp{
					font-size: 2.8rem;
					top: -4px;
				}
			}
		}
	}
}
