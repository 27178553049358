//============================================
// サイト幅でセンタリング
//============================================
//@mixin center{
//	width: $baseWidth;
//	margin: 0 auto;
//}

//============================================
// clearfix
//============================================
@mixin clear {
	*zoom: 1;
	&:after {
		display: block;
		content: "";
		clear: both;
	}
}

//============================================
// フォント
//============================================
@mixin mincyo() {
	font-family: "游明朝", YuMincho, "ヒラギノ明朝 ProN W3",
		"Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;
}

@font-face {
	font-family: "NotoSansJP";
	src: url(assets/font/NotoSansJP-Regular.otf);
}
@font-face {
	font-family: "DIN";
	src: url(assets/font/DIN-Medium.otf);
}
@font-face {
	font-family: "DIN-Bold";
	src: url(assets/font/DIN-Bold.otf);
}
@font-face {
	font-family: "NotoSansJP-Bold";
	src: url(assets/font/NotoSansJP-Bold.otf);
}
@font-face {
	font-family: "Diot";
	src: url(assets/font/Didot.ttc);
}
@font-face {
   font-family: "Shree714";
   src: url(assets/font/ShreeDev0714-01.ttf);
}

@font-face {
   font-family: "Shree Devanagari 714";
   src: url(assets/font/ShreeDev0714-01.ttf);
}
//============================================
// breakpoint
//============================================
@mixin sp {
	@media screen and (max-width: #{$breakpoint1}) {
		@content;
	}
}
