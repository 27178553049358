/*------------------------------------------------------------
breadcrumb
------------------------------------------------------------*/

/*
------------------------------------------------------------*/
.c-breadcrumb{
	display:flex; 
	flex-wrap: wrap;
	li{
		padding-right: 17px;
	}
	a{
		color:#4d4d4d;
		font-size:1.2rem;
		text-decoration: none;
		position: relative;
		&:after{
			content:">";
			position: absolute;
			top:-2px;
			right:-13px;
		}
	}
	span{
		color:#000000;
		font-size:1.2rem;
		// border-bottom:1px solid #8F8F8F;
	}
}