/*------------------------------------------------------------
header
------------------------------------------------------------*/

/*
------------------------------------------------------------*/
/*------------------------------------------------------------
header
------------------------------------------------------------*/

/*
------------------------------------------------------------*/
.c-header {
	background: #FFF;
	position: relative;
	@include sp {
		text-align: center;
	}
	&__logo {
		text-align: center;
		padding: 69px 0 64px;
		@include sp {
			text-align: left;
			padding: 26px 0 25px;
		}
	}
	&__menu{
		// display: block;
		@include sp{
			visibility: hidden;
			opacity: 0;
			position: fixed;
			top: 0;
			bottom:0;
			left:0;
			width: 100%;
			height: 100vh;
			background: rgba(244, 237, 235, 0.95);
			z-index: 111;
			padding-top: 110px;
		}
		&--up{
			@include sp{
				transform: translateY(0);
				visibility: visible;
				opacity: 1;
			}
		}
	}
	&__nav {
		display: flex;
		justify-content: center;
		margin-bottom: 17px;
		@include sp {
			display: block;
			margin-bottom: 0;
			overflow: scroll;
   			 height: 100vh;
		}
		li {
			text-align: center;
			margin-right: 95px;
			@include sp {
				margin-left: 20px;
				margin-right: 20px;
				padding-bottom: 32px;
				text-align: left;
				// background: rgba(244,237,235,1);
			}
			&:first-child {
				@include sp {
					padding-top: 50px;
				}
			}
			&:nth-child(5) {
				margin-right: 0;
				@include sp{
					padding-top: 0;
				}
			}
			&:last-child {
				margin-right: 0;
				@include sp{
					padding-top: 40px;
					padding-bottom:100%;
				}
			}
			a {
				text-decoration: none;
				display: block;
				padding-bottom: 4px;
				border-bottom: 4px solid #FFF;
				transition: 0;
				@include sp {
					border-bottom: none;
				}
				&:hover{
					opacity: 0.5;
					transition: 0.3s ease-in-out;
					@include sp{
						border-bottom: none;
					}
				}
			}
			h3 {
				font-size: 1.6rem;
				font-weight: normal;
				color: #333;
				font-family: "Shree Devanagari 714";
				letter-spacing: 1px;
				@include sp {
					display: inline-block;
					font-weight: normal;
					font-size: 2.3rem;
				}
			}
			p {
				font-size: 1.4rem;
				color: #989898;
				font-family: "Noto Sans CJK JP Light", "ヒラギノ角ゴ Pro","Hiragino Kaku Gothic Pro","メイリオ",Meiryo,sans-serif;
				letter-spacing: 1px;
				@include sp {
					display: inline-block;
					margin-left: 10px;
				}
			}
		}
	}
	&__btn{
		position: absolute;
		top:26px;
		right:23px;
		z-index: 1111;
		width:25px;
		height:25px;
		span{
			display: block;
			width:25px;
			height: 1px;
			background: #C0153D;
			& + span{
				margin-top:7px;
			}
		}
		&--move{
			span{
				&:nth-child(1){
					transform: translateY(7px) rotate(45deg);
				}
				&:nth-child(2){
					display: none;
				}
				&:nth-child(3){
					transform: translateY(-1px) rotate(-45deg);
				}
			}
		}
	}
	&__2{
		border-bottom:1px solid #E6E6E6;
		@include sp{
			border-bottom:none;
		}
		.l-wrapper{
			display: flex;
			@include sp{
				display: block;
			}
		}
		.c-header__logo{
			padding:27px 0 44px;
			@include sp {
				text-align: left;
				padding: 26px 25px 25px;
			}
		}
		.c-header__menu{
			margin: 54px 0 0 104px;
			@include sp{
				margin: 0;
			}
		}
		.c-header__nav li {
			margin-right: 100px;
			&:last-child {
				margin-right: 0;
				@include sp{
					padding-top: 0;
				}
			}
			@include sp{
				margin-right: 0;
			}
			a{
			  padding-bottom:8px;
			}
			/*h3{
				font-size:1.6rem;
			}*/
			p{
				font-size:1.4rem;
			}
		}
	}
}
